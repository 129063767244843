import React from "react";

// import { ICredentials} from "aws-amplify"

export interface ICredentials {
  attributes: {
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    registered: boolean;
    emailConfirmed: boolean;
    registeredUntil?: string;
    referrer: string | null;
  };
}

interface IContextProps {
  userState: ICredentials;
  setStateUserState: React.Dispatch<React.SetStateAction<ICredentials>>;
}

const UserContext = React.createContext<IContextProps>({} as IContextProps);

function useUserContext() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`userContext must be used within a UserProvider`);
  }
  return context;
}

const unauthState = () => ({
  attributes: {
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
    registered: false,
    registeredUntil: "",
    emailConfirmed: false,
    referrer: null
  }
});

function UserProvider(props: any) {
  const [userState, setStateUserState] = React.useState<ICredentials>(
    unauthState()
  );
  const value = React.useMemo<IContextProps>(
    () => ({ userState, setStateUserState }),
    [userState]
  );

  return <UserContext.Provider value={value} {...props} />;
}
export { UserProvider, unauthState, useUserContext };
