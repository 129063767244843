import React, { useEffect } from "react";
//import appSyncConfig from "../aws-exports";
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient, HttpLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client";
import { history } from "../App";

import Loading from "../components/loading/loading";
import { ICredentials, useUserContext } from "../context/user-context";

import { graphlQlUri } from "../uris";

import { appFetch } from "../helpers/app-fetch";
import { frontEnd } from "../config/links";

const link = new HttpLink({
  uri: graphlQlUri
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

/// todo: impliment rolling auth tokens
const resetToken = onError(err => {
  //debugger;
  const { response, networkError, graphQLErrors } = err;
  if (graphQLErrors) {
    graphQLErrors.map(err => {
      // LogRocket.captureException({
      //   name: err.name,
      //   message: err.message
      // });
    });
  }

  const customError = networkError as any;

  if (customError && customError!.statusCode === 401) {
    // remove cached token on 401 from the server
    localStorage.removeItem("token");
    history.push(frontEnd.login);
    //networkError = undefined;
  }
}).concat(link);

export const client = new ApolloClient({
  uri: graphlQlUri,
  headers: {
    authorization: localStorage.getItem("token") || ""
  },
  link: authLink.concat(resetToken),
  // cache: new InMemoryCache({ dataIdFromObject: o => o.id })
  cache: new InMemoryCache()
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'cache-and-network',
  //   },
  // },
});

//this will make sure the user state is set before page loads
const WithProvider: React.FunctionComponent = ({ children }) => {
  const { userState, setStateUserState } = useUserContext();
  useEffect(() => {
    getUser();
    async function getUser() {
      let user: ICredentials;
      const token = localStorage.getItem("token");

      if (!token) return null;

      let response = await appFetch("/api/auth/validate", {
        method: "GET"
      });

      if (!response?.value) {
        localStorage.removeItem("token");
        history.push("/");
        return null;
      }
      //this will be setting local storage to current user instead
      //LogRocket.identify(response.value);
      localStorage.setItem("user", JSON.stringify({ email: response.value }));
      //todo configure dynamic routing
      //history.push(frontEnd.dashboardHome);

      if (response.value.email !== userState.attributes.email)
        setStateUserState({
          attributes: {
            email: response.value.email,
            firstName: response.value.firstName,
            lastName: response.value.lastName,
            roles: response.value?.roles || [],
            registered: response.value?.registered,
            registeredUntil: response.value?.registeredUntil,
            emailConfirmed: response.value?.emailConfirmed,
            referrer: userState?.attributes?.referrer ?? null
          }
        });
    }
  }, [userState.attributes.email, setStateUserState]);

  if (!userState) return <Loading setSize={400} />;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default WithProvider;
