import React from "react";

// import { ListQuestionsQueryVariables } from "../../graphql/API";

import { Draft } from "immer";
import { useImmer } from "../../helpers/use-immer";

import { SubTopic } from "../../graphql/API";
interface IPage {
  previousToken: string[];
  nextToken: string | null;
}

export interface IState {
  page: number;
  limit: number;
  subtopic: SubTopic | undefined;
}

interface IContextProps {
  questionVariablesState: IState;
  setQuestionVariables: (f: (draft: Draft<IState>) => void | IState) => void;
}

const QuestionVariablesContext = React.createContext<IContextProps>(
  {} as IContextProps
);

function useQuestionVariablesContext() {
  const context = React.useContext(QuestionVariablesContext);
  if (!context) {
    throw new Error(
      `questionVariableContext must be used within a QuestionVariableProvider`
    );
  }
  return context;
}

const defaultState = (): IState => ({
  page: 1,
  limit: 10,
  subtopic: undefined
});

function QuestionVariablesProvider(props: any) {
  const [questionVariablesState, setQuestionVariables] = useImmer<IState>(
    defaultState()
  );
  const value = React.useMemo<IContextProps>(
    () => ({ questionVariablesState, setQuestionVariables }),
    [questionVariablesState, setQuestionVariables]
  );

  return <QuestionVariablesContext.Provider value={value} {...props} />;
}
export { QuestionVariablesProvider, defaultState, useQuestionVariablesContext };
