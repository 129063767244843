import React from "react";

import styled from "styled-components";

//router
import { Link, useLocation } from "react-router-dom";

//components
import { ButtonPrimary } from "../../components/buttons/button";

import { H2 } from "../authorization/styled-components";
//assets
import MiddleBackground from "../../assets/middleBackground.svg";

import { SV } from "../../styles/styles-variables";
import Footer from "./footer";
import { useUserContext } from "../../context/user-context";
import { frontEnd } from "../../config/links";
import useLocalStorage from "../../helpers/use-local-stroage";

const PricingSection = () => {
  const location = useLocation();
  const { userState } = useUserContext();
  const [redirectToPurchase, setRedirectToPurchase] = useLocalStorage(
    "purchaseRedirect",
    false
  );
  console.log(redirectToPurchase);
  const loggedIn = userState.attributes.email != "";

  const hideZ = location.pathname !== "/";

  const buttonText = loggedIn ? "Upgrade" : "Join";
  const redirectLink = loggedIn ? frontEnd.purchase : frontEnd.register;
  return (
    <>
      {/* todo change alll png images to svg - much smaller file size */}
      {/* todo uniform section margins */}
      <div style={{ width: "100%", overflowY: "hidden" }}>
        <BottomBGImage src={MiddleBackground} alt="" />
        <Container>
          <section id="plan-section">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "120px"
              }}
            >
              <div>
                <H2> Find a plan that's right for you</H2>
                <AboutUsText>
                  During the beta phase, GetThru™ is completely free. We request
                  feedback on improving the product from our beta testers.
                </AboutUsText>
              </div>
            </div>
            <PlanBoxesFlex>
              <PlanBoxStyled HideZ={hideZ}>
                <h3> 1 month </h3>
                <h4>$90</h4>
                <Link to={redirectLink} style={{ width: "100%" }}>
                  <ButtonPrimary
                    text={buttonText}
                    onClick={() => {
                      setRedirectToPurchase(true);
                    }}
                    wide
                  ></ButtonPrimary>
                </Link>
              </PlanBoxStyled>
              <PlanBoxStyled HideZ={hideZ}>
                <Ribbon>
                  <span>Recommended</span>
                </Ribbon>
                <h3> 3 months </h3>
                <h4>$150</h4>
                <Link to={redirectLink} style={{ width: "100%" }}>
                  <ButtonPrimary
                    text={buttonText}
                    onClick={() => {
                      setRedirectToPurchase(true);
                    }}
                    wide
                  ></ButtonPrimary>
                </Link>
              </PlanBoxStyled>
              <PlanBoxStyled HideZ={hideZ}>
                <h3> 6 months </h3>
                <h4>$220</h4>
                <Link to={redirectLink} style={{ width: "100%" }}>
                  <ButtonPrimary
                    text={buttonText}
                    onClick={() => {
                      setRedirectToPurchase(true);
                    }}
                    wide
                  ></ButtonPrimary>
                </Link>
              </PlanBoxStyled>
            </PlanBoxesFlex>
          </section>
          <Footer />
        </Container>
      </div>
    </>
  );
};

export const Ribbon = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  font-size: 16px;
  ::before,
  ::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #f8d562;
  }
  span {
    position: absolute;
    display: block;
    z-index: -1;
    width: 225px;
    padding: 15px 0;
    background-color: #f8d562;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 16px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

    text-align: center;
  }

  /* top left*/

  top: -10px;
  left: -10px;

  ::before,
  ::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  ::before {
    top: 0;
    right: 0;
  }
  ::after {
    bottom: 0;
    left: 0;
  }
  span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
`;

const PlanBoxesFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin-top: 40px;
`;

export const PlanBoxStyled = styled.div<{ HideZ: boolean }>`
  position: relative;
  z-index: 1;
  background-color: white;
  border: 2px dashed ${SV.colors.darkMain1};
  border-radius: 5px;
  width: 280px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 53px;
  margin: 10px;
  ${p => p.HideZ && "z-index: -1;"}
  h3 {
    margin-top: 20px;
    font-weight: bold;
    font-size: 21px;
  }
  h4 {
    span {
      font-size: 56px;
    }
    font-weight: 500;
    font-size: 45px;
  }
  :hover {
    z-index: 1;
  }
`;

//

const BottomBGImage = styled.img`
  position: absolute;
  top: calc(100% - 150vh);
  left: 0;
  // width: 100vw;
  height: 300vh;
  transform: scaleX(-1);
  overflow-y: hidden !important;
  z-index: -1;
  background-color: white;
`;

const AboutUsText = styled.div`
  color: ${SV.colors.darkMain1};
  font-size: 14px;
  line-height: 160%;
  margin-top: -20px;
  max-width: 560px;
`;

const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 40px;
  @media (min-width: 1240px) {
    padding: 0;
  }
`;

export default PricingSection;
