import React from "react";

import styled from "styled-components";

const ModalDiv = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #2f80ed;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
  ${p =>
    p.active &&
    `
z-index: 0;
  opacity: 0.7;
  `}
`;

export default ModalDiv;
