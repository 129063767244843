import React from "react";
import styled from "styled-components";

const SpacerBar = styled.div`
  border-top: 1px solid #e4e4e4;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  /* height: 1; */
`;

export { SpacerBar };
