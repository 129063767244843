import React from "react";

import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import { SV } from "../../styles/styles-variables";

export const TextLink = styled<any>(Link)`
  font-size: 14px;
  font-weight: 600;
  color: ${SV.colors.interactive1};
  text-align: right;
  :hover {
    text-decoration-line: underline;
  }
  :active {
    text-decoration-line: underline;
  }
  :focus {
    text-decoration-line: underline;
  }

  ${p =>
    p.disabled &&
    css`
      color: ${SV.colors.disabled1};
    `}
` as React.FunctionComponent<any>;
