import React from "react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//graphQL imports
import CustomProvider from "./graphql/configureProvider";

//style imports
import GlobalStyles from "./styles/globalStyles";

//contexts
import { UserProvider } from "./context/user-context";
import { QuestionVariablesProvider } from "./pages/admin/admin-context";

import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(
    <QuestionVariablesProvider>
      <UserProvider>
        <CustomProvider>
          <GlobalStyles />
          <App />
        </CustomProvider>
      </UserProvider>
    </QuestionVariablesProvider>,
    rootElement
  );
} else {
  render(
    <QuestionVariablesProvider>
      <UserProvider>
        <CustomProvider>
          <GlobalStyles />
          <App />
        </CustomProvider>
      </UserProvider>
    </QuestionVariablesProvider>,
    rootElement
  );
}

// ReactDOM.render(
//   <QuestionVariablesProvider>
//     <UserProvider>
//       <CustomProvider>
//         <GlobalStyles />
//         <App />
//       </CustomProvider>
//     </UserProvider>
//   </QuestionVariablesProvider>,
//   rootElement
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
