import React, { useEffect, useRef } from "react";

const useOutsideClick = (open, ref, callback) => {
  const handleClickOutside = e => {
    if (ref?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    callback();
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
};

export { useOutsideClick };
