// const SV = {
//   // colors: {
//   //   main1: "#2F80ED",
//   //   darkMain1: "#010842",
//   //   darkMain2: "#3860C8",
//   //   darkMain3: "#050925",
//   //   grey1: "#F6F7FB",
//   //   grey2: "#ddd",
//   //   grey4: "#BDBDBD",
//   //   green2: "#27AE60",
//   //   green2BG: "rgba(102, 193, 161, 0.15)",
//   //   red: "#EB5757",
//   //   redBG: "rgba(235, 87, 87, 0.1)",
//   //   orange1: "#ED8162",
//   //   black: "#050925",
//   //   interactive1: "#ED8162",
//   //   interactive2: "rgb(250, 196, 181)",
//   //   fill1: "#2F80ED",
//   //   disabled1: "#BDBDBD"
//   // }
//   // colors: { //light blur/orange scheme
//   //   main1: "#2F7FED", //blue graphs
//   //   orange1: "#ED8162", //orange graphs
//   //   darkMain1: "#010842",
//   //   darkMain2: "#3860C8",
//   //   darkMain3: "#050925",
//   //   grey1: "#F6F7FB",
//   //   grey2: "#ddd",
//   //   grey4: "#BDBDBD",
//   //   green2: "#27AE60",
//   //   green2BG: "rgba(102, 193, 161, 0.15)",
//   //   red: "#EB5757",
//   //   redBG: "rgba(235, 87, 87, 0.1)",

//   //   black: "#050925",
//   //   interactive1: "#FF9F00",
//   //   interactive2: "#FFCE7C",
//   //   fill1: "#2F80ED",
//   //   disabled1: "#BDBDBD",
//   //   quat1: "#FFE47C"
//   // },

// };

export const colors = {
  //light and dark blue schem
  main1: "#5697F0", //blue graphs
  orange1: "#ED8162", //orange graphs
  darkMain1: "#010842",
  darkMain2: "#3860C8",
  darkMain3: "#050925",
  grey1: "#F6F7FB",
  grey2: "#ddd",
  grey4: "#BDBDBD",
  green2: "#27AE60",
  green2BG: "rgba(102, 193, 161, 0.15)",
  red: "#EB5757",
  redBG: "rgba(235, 87, 87, 0.1)",
  black: "#050925",
  interactive1: "#230ABE",
  interactive2: "rgb(189, 198, 253)", //"#9687F4",
  interactiveDark: "rgb(30, 11, 156)",
  fill1: "#2F80ED",
  disabled1: "#BDBDBD",
  disabled2: "#565759",
  quat1: "#FFE47C"
};

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  dashboard1: "610px",
  tablet: "768px",
  tabletL: "950px",
  laptop: "1024px",
  dashboard2: "1100px",
  laptopL: "1440px",
  desktop: "2560px",
  sectionMargin: "120px",
  containerSize: 1240,
  mobileNavBreakpoint: 850
};

export const MQ = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  dashboard1: `(min-width: ${size.dashboard1})`,
  dashboard2: `(min-width: ${size.dashboard2})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
  mobileNavBreakpoint: `(min-width: ${size.mobileNavBreakpoint}px)`
};

const SV = {
  colors,
  MQ,
  size
};

export { SV };
