import React from "react";

import styled, { css } from "styled-components";

//
import { useUserContext } from "../../context/user-context";

//router
import { Link, useLocation } from "react-router-dom";
import { history } from "../../App";

//components
import { ButtonSecondary } from "../../components/buttons/button";
import Logo from "../../components/logo";
import AuthContainer from "./auth-container";

//assets
import TopLeftBackground from "../../assets/TopleftBackground.png";
import TopLeftBackgroundWebp from "../../assets/TopleftBackground.webp";
import TopLeftBackgroundSvg from "../../assets/TopleftBackground2.svg";
import ModalDiv from "../../components/modal-div";
import AboutUsSection from "./about-us-section";
import LandingSection from "./landing-section";

import { frontEnd } from "../../config/links";
import { MQ } from "../../styles/styles-variables";
import ExperienceSection from "./experience-section";
import PricingSection from "./pricing-section";
import CheckoutForm from "../stripe/stripe";

const Home = () => {
  const { pathname, search } = useLocation();

  const { userState, setStateUserState } = useUserContext();

  let query = new URLSearchParams(search);
  let referrer = query.get("ref");
  // console.log(query.get("ref"));

  if (userState?.attributes?.referrer != referrer && referrer) {
    setStateUserState(prev => ({
      ...prev,
      attributes: { ...prev.attributes, referrer }
    }));
  }

  return (
    <div style={{ position: "relative" }}>
      <ModalDiv
        active={pathname !== "/"}
        onClick={() => {
          history.push("/");
        }}
      />
      {/* <TopLeftBackgroundPng src={TopLeftBackground} alt="" /> */}
      {/* <link rel="preload" as="image" href={TopLeftBackgroundSvg} /> */}
      {/* <TopLeftBackgroundPng>
        <source
          type="image/webp"
          //@ts-ignore
          width={1150}
          height={644}
          srcSet={TopLeftBackgroundWebp}
        />
        <source type="image/png" srcSet={TopLeftBackgroundPng} />
        <img src={TopLeftBackgroundPng} alt="" />
      </TopLeftBackgroundPng> */}
      <TopLeftBackgroundSvgX src={TopLeftBackgroundSvg} alt="" />

      {/* todo fix nav sticky make it go to bottom */}
      <div style={{ position: "relative", overflowY: "hidden" }}>
        <NavDiv hideBG={pathname !== "/"}>
          <Nav>
            <div style={{ order: 1 }}>
              <Logo />
            </div>

            <Spacer />
            <CentralNavBlock>
              {/* <li> */}
              <a href="/#about-us">About</a>
              {/* </li> */}
              {/* <li> */}
              <a href="/#experience">Features</a>
              {/* </li> */}
              {/* <li> */}
              <a href="/#plan-section">Pricing</a>
              {/* </li> */}
            </CentralNavBlock>
            <NavButtonContainer>
              <AuthContainer />

              <div style={{ display: "flex" }}>
                <Link
                  to={
                    userState.attributes?.email
                      ? frontEnd.dashboardHome
                      : frontEnd.login
                  }
                >
                  <ButtonSecondary
                    text={
                      userState.attributes?.email
                        ? "Go to dashboard"
                        : "Sign in"
                    }
                  />
                </Link>
              </div>
            </NavButtonContainer>
            {/* <div>
              {!userState.attributes?.email && (
                <Link
                  style={{ marginLeft: "10px", order: 4 }}
                  to={frontEnd.register}
                >
                  <ButtonSecondary text={"Sign up"} />
                </Link>
              )}
            </div> */}
          </Nav>
        </NavDiv>
        <div style={{ zIndex: -1 }}>
          <Container hide={pathname !== "/"}>
            <LandingSection />
            <AboutUsSection />
          </Container>
          <ExperienceSection />
          <PricingSection />
        </div>
      </div>

      {/* todo finish footer */}
    </div>
  );
};

const NavButtonContainer = styled.div`
  position: relative;
  /* width: "200px"; */
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 90;

  order: 2;
  @media (min-width: 535px) {
    order: 4;
  }
`;

const Spacer = styled.div`
  flex: 1;
  order: 2;
  visibility: hidden;
  @media ${MQ.tablet} {
    visibility: visible !important;
  }
`;

const TopLeftBackgroundPng = styled.picture`
  * {
    position: absolute;
    top: calc(0 - 100px);
    left: 0;
    width: 100vw;
    // height: 100vw;
    z-index: -1;

    @media ${MQ.mobileL} {
      width: 80vw;
      height: 80vh;
    }
  }
`;

const TopLeftBackgroundSvgX = styled.img`
  position: absolute;
  top: calc(0 - 100px);
  left: 0;
  width: 100vw;
  // height: 100vw;
  z-index: -1;

  @media ${MQ.mobileL} {
    // width: 80vw;
    // height: 80vh;
  }
`;

const Container = styled.div<{ hide: boolean }>`
  max-width: 1240px;
  margin: 0 auto;
  z-index: -1;
  padding: 0 40px;
  ${p =>
    p.hide &&
    css`
      * {
        z-index: -1;
        button {
          span {
            z-index: 1;
          }
        }
      }
    `};
  @media (min-width: 1240px) {
    padding: 0;
  }
`;

const NavDiv = styled.div<{ hideBG: boolean }>`
  background-color: rgba(256, 256, 256, 0.7);
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  ${p => p.hideBG && "background-color: inherit;"}
  display: flex;
  justify-content: center;
  z-index: 10;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: sticky; */

  z-index: 70;
  width: 100%;
  max-width: 1240px;
  margin: 0 20px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const CentralNavBlock = styled.div`
  display: flex;
  order: 3;
  /* width: 40%; */
  justify-content: space-between;
  list-style-type: none;
  max-width: 300;
  font-weight: 600;
  font-size: 14px;
  /* min-width: 300; */
  padding-right: 20px;
  a {
    padding: 10px 10px;
    @media ${MQ.laptop} {
      padding: 10px 30px;
    }
    cursor: pointer;
  }
  li {
    padding: 10px 10px;
    @media ${MQ.laptop} {
      padding: 10px 30px;
    }
    cursor: pointer;
  }
`;

export default Home;
