import React from "react";
import styled from "styled-components";

import { H2 } from "../authorization/styled-components";
//assets

import HomePageImage2 from "../../assets/HomePageImage2.png";
import HomePageImage2Webp from "../../assets/HomePageImage2.webp";
import LeftQuote from "../../assets/left-quote.png";
import MadeForDoctorsImage from "../../assets/MadeForSurgeons.png";
import { MQ, SV } from "../../styles/styles-variables";
import Carousel from "./carousel";

const AboutUsSection = () => {
  return (
    <section id="about-us">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // backgroundColor: "blue"
          width: "100%"
        }}
      >
        <H2 style={{ paddingTop: 50 }}>Testimonals</H2>
        <ContainerDiv>
          <Carousel
            slides={[
              {
                quote: `Great app! Questions are based directly from the textbooks so you
              know it's the right content, and super easy to use on the go for
              opportunistic study. Would use again!`,
                name: "- Jessica, sat GSSE November 2023"
              },
              {
                quote: `I found get thru an extremely useful tool. The explanations were super helpful for study and I found taught me information in areas which I was lacking. The page references also helped me read around areas I needed to improve on. great resource.`,
                name: "- Nicholas, sat GSSE November 2023"
              },
              {
                quote: `I have found Get Thru an effective way of reviewing GSSE content, with an extensive bank of new questions as well as the traditional bank in a portable and much more user friendly format.`,
                name: "- Chris, studying for the GSSE"
              }
            ]}
          />
        </ContainerDiv>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center"
        }}
      >
        <div style={{ margin: "60px 0" }}>
          <H2> Boost your study.</H2>
          <AboutUsText>
            GetThru™ is <b>fully mobile compatible </b>and will give you a
            headstart on preparation for the GSSE.
          </AboutUsText>
        </div>
      </div>
      <link rel="preload" as="image" href={HomePageImage2Webp} />
      <Flex>
        <picture>
          <source type="image/webp" srcSet={HomePageImage2Webp} />
          <source type="image/png" srcSet={HomePageImage2} />
          <img
            src={HomePageImage2}
            alt="Cartoon image of a person using a laptop"
          />
        </picture>

        {/* <img
          src={HomePageImage2}
          alt="Cartoon image of a person using a laptop"
        /> */}
        <AboutUsSideText>
          <div>
            GetThru™ provides an intuitive, easy to use and mobile question bank
            for you to practice – providing you with instant feedback,
            explanations for answers and progress tracking. We have designed
            this app with you in mind.
          </div>
          <div>
            We recognise the need to be able to keep practicing whenever,
            wherever. That is why we have created this educational tool to help.
            Our aim is to create both the best and the most affordable app on
            the market.
          </div>
          <Img
            src={MadeForDoctorsImage}
            alt="Made by surgeons for future surgeons."
            // style={{ marginRight: "-500px" }}
          />
        </AboutUsSideText>
      </Flex>
    </section>
  );
};

const ContainerDiv = styled.div`
  width: 300px;

  @media ${MQ.mobileL} {
    width: 400px;
  }

  @media ${MQ.tablet} {
    width: 500px;
  }

  @media ${MQ.laptop} {
    width: 850px;
  }
`;

const Img = styled.img``;

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
`;

const AboutUsSideText = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${SV.colors.darkMain1};
  padding: 0 40px;
  div {
    max-width: 370px;
    margin-bottom: 30px;
  }
`;

const AboutUsText = styled.div`
  color: ${SV.colors.darkMain1};
  font-size: 18px;
  line-height: 160%;
  margin-top: -20px;
  max-width: 400px;
`;

export default AboutUsSection;
