import React, { useState } from "react";
import styled from "styled-components";
import { H2 } from "../authorization/styled-components";
import LeftQuote from "../../assets/left-quote.png";
import { MQ } from "../../styles/styles-variables";

// Styled Components
const CarouselContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Slide = styled.div`
  flex: 0 0 100%;
  transition: all 0.5s ease;
  width: 100%;
`;

const SlideImage = styled.img`
  width: 100%;
  height: auto;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-150%);
  @media ${MQ.tablet} {
    transform: translateY(-150%);
  }
  @media ${MQ.laptop} {
    transform: translateY(-200%);
  }
  //   transform: translateX(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
  font-size: 20px;

  &:first-child {
    left: 2px;
  }

  &:last-child {
    right: 2px;
  }
`;

// Carousel Component
const Carousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(prev => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <CarouselContainer>
      <NavigationButton onClick={prevSlide}>&lt;</NavigationButton>
      {slides.map((slide, index) => (
        <>
          <Slide
            key={index}
            style={{
              transform: `translateX(-${currentSlide * 100}%)`,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <ContainerDiv>
              {/* <a href="https://www.flaticon.com/free-icons/quote" title="quote icons">Quote icons created by Freepik - Flaticon</a> */}
              <img
                src={LeftQuote}
                style={{
                  width: 20,
                  textAlign: "left",
                  marginTop: -20,
                  paddingBottom: 10
                }}
              />
              <h3>{slide.quote}</h3>
              <div style={{ padding: 20 }}>{slide.name}</div>
            </ContainerDiv>
            {/* <SlideImage src={slide.image} alt={slide.caption} /> */}
          </Slide>
        </>
      ))}
      <NavigationButton onClick={nextSlide}>&gt;</NavigationButton>
    </CarouselContainer>
  );
};

const ContainerDiv = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  div {
    font-size: 16px;
  }
  h3 {
    font-size: 20px;
  }

  @media ${MQ.mobileL} {
    width: 330px;
    h3 {
      font-size: 16px;
    }
  }

  @media ${MQ.tablet} {
    width: 450px;
    h3 {
      font-size: 18px;
    }
  }

  @media ${MQ.laptop} {
    width: 810px;
    h3 {
      font-size: 20px;
    }
  }
`;

export default Carousel;
