import React, { lazy, Suspense } from "react";

//routing imports
import { Route, Router, Switch } from "react-router-dom";

//HMR
import { hot } from "react-hot-loader";

//components static

import history from "./history";

import { frontEnd } from "./config/links";

//import LogRocket from "logrocket";

import Http404Page from "./pages/http404page";

// import Profile from "./pages/authorization/profile";
// import ResetPassword from "./pages/authorization/reset-password";
import Home from "./pages/home";
import Purchase from "./pages/Dashboard/purchase";

//
//dashbaord pages
//
// import Revise from "./pages/Dashboard/revise";
//LogRocket.init("zdz1em/getthru");

// import ReviewReport from "./pages/admin/review-report";
// import Admin from "./pages/admin";
// import Results from "./pages/Dashboard/results";
// import BulkUpload from "./pages/admin/bulk-upload";
// import SingleUpload from "./pages/admin/addQuestion";
// import Dashboard from "./pages/Dashboard/dashboard-wrapper";
// import DashboardHome from "./pages/Dashboard/home";
// import Revise from "./pages/Dashboard/revise";
// import Review from "./pages/Dashboard/review";
// import ResetPassword from "./pages/authorization/reset-password";
//import ResendConfirmEmail from "./pages/authorization/resend-confirm-email"

const ResendConfirmEmail = lazy(() =>
  import("./pages/authorization/resend-confirm-email")
);
const Admin = lazy(() => import("./pages/admin"));
const Profile = lazy(() => import("./pages/authorization/profile"));
const ResetPassword = lazy(() =>
  import("./pages/authorization/reset-password")
);
const ConfirmEmail = lazy(() => import("./pages/authorization/confirm-email"));
const Revise = lazy(() => import("./pages/Dashboard/revise"));
const Review = lazy(() => import("./pages/Dashboard/review"));
const ReviewReport = lazy(() => import("./pages/admin/review-report"));

const SingleUpload = lazy(() => import("./pages/admin/addQuestion"));
const BulkUpload = lazy(() => import("./pages/admin/bulk-upload"));
const ExamEnd = lazy(() => import("./pages/Dashboard/examEnd"));
const Results = lazy(() => import("./pages/Dashboard/results"));
const Dashboard = lazy(() => import("./pages/Dashboard/dashboard-wrapper"));
const DashboardHome = lazy(() => import("./pages/Dashboard/home"));

const App = () => {
  return (
    <Router history={history}>
      {/* <NavBar /> */}
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route
            path="/(login/index.html|register/index.html|login|register|register-success|forgot-password|forgot-password/index.html|confirm-email|confirm-email/index.html|closed-for-alpha|resend-confirm-email|reset-password|)/"
            component={Home}
          />
          <Route path={frontEnd.profile} exact component={Profile} />

          <Route path={frontEnd.bulkUpload} exact component={BulkUpload} />
          <Route path={frontEnd.reviewReports} exact component={ReviewReport} />
          <Route path={frontEnd.singleUpload} exact component={SingleUpload} />
          <Route path={frontEnd.results} component={Results} />
          <Route path={frontEnd.review} exact component={Review} />

          <Route path={frontEnd.admin} exact component={Admin} />
          <Route path={frontEnd.confirmEmail} exact component={ConfirmEmail} />
          <Route
            path={frontEnd.resendConfirmEmail}
            component={ResendConfirmEmail}
          />
          <Route
            path={frontEnd.resetPassword}
            exact
            component={ResetPassword}
          />
          {/* <Route path={frontEnd.forgotPassword} component={ForgotPassword} /> */}
          <Route path="/dashboard">
            <DashboardRoutes />
          </Route>

          <Route component={Http404Page} />
        </Switch>
        <Switch></Switch>
      </Suspense>
    </Router>
  );
};

const DashboardRoutes = () => (
  <Dashboard>
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path={frontEnd.dashboardHome} component={DashboardHome} />
        <Route path={frontEnd.revise} exact component={Revise} />
        <Route path={frontEnd.testResults} exact component={ExamEnd} />
        <Route path={frontEnd.purchase} exact component={Purchase} />
      </Switch>
    </Suspense>
  </Dashboard>
);

export { history };

//@ts-ignore

export default hot(module)(App);
