let graphlQlUri: string;

let useLocal = true;

let domain = 'surgicalmcqapi2';
// let domain = 'surgicalmcqappserverless3';

if (process.env.NODE_ENV == "development" && useLocal) {
  // graphlQlUri = "https://surgicalmcqapi.azurewebsites.net/graphql";
  // console.log("using local")
  graphlQlUri = `http://localhost:56019/graphql`;
} else if (process.env.NODE_ENV == `development` && !useLocal){

  graphlQlUri = `https://${domain}.azurewebsites.net/graphql`;
  // graphlQlUri = `https://${domain}.azurewebsites.net/graphql`;
}
else {
  
  graphlQlUri = `https://${domain}.azurewebsites.net/graphql`;
}

let restUri: string;
if (process.env.NODE_ENV == `development` && useLocal) {
  // restUri = `https://surgicalmcqapi.azurewebsites.net`;
  restUri = `http://localhost:56019`;
}
else if (process.env.NODE_ENV == `development` && !useLocal){

  
  restUri = `https://${domain}.azurewebsites.net`;
} 

else {
  restUri = `https://${domain}.azurewebsites.net`;
}

export { graphlQlUri, restUri };
