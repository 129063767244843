import React from "react";
import styled from "styled-components";
import { MQ } from "../../../styles/styles-variables";

const Table = styled.table`
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
  font-size: 14px;

  @media ${MQ.laptop} {
    font-size: 18px;
  }
`;

const Th = styled.th`
  background-color: #f4f4f4;
  padding: 12px;
  border: 1px solid #ddd;

  text-align: left;
`;

const Td = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const CheckMark = styled.span`
  font-size: 14px;
  @media ${MQ.laptop} {
    font-size: 18px;
  }
  color: green;
  font-weight: bold;
`;

const CrossMark = styled.span`
  font-size: 14px;
  @media ${MQ.laptop} {
    font-size: 18px;
  }
  color: red;
  font-weight: bold;
`;

const features = [
  {
    feature: '1500+ Questions from "The Bank"',
    free: true,
    premium: true
  },
  {
    feature: "2000+ Novel GSSE-Specific Questions from Get-Thru",
    free: false,
    premium: true
  },
  {
    feature: "Detailed Explanations & References",
    free: false,
    premium: true
  },
  { feature: "Mobile & Web Access", free: true, premium: true },
  {
    feature: "Tailored Study with Sub-Topic Selection",
    free: false,
    premium: true
  },
  //   { feature: 'Results Page', free: true, premium: true },
  {
    feature: "Customised Learning with Test Mode Options",
    free: false,
    premium: true
  },
  {
    feature: "Efficient Study with Spaced Repetition Algorithms",
    free: false,
    premium: true
  },
  { feature: "Focused Practice with Exam Mode", free: false, premium: true },
  { feature: "Anatomy Spot Questions", free: false, premium: true },
  {
    feature: "100% money back guarantee*",
    free: "N/A",
    premium: true,
    link: true
  }
  //   { feature: 'Exam mode (Limited questions, limited time)', free: false, premium: 'Added post-beta' },
  //   { feature: 'Access to Spot Questions', free: false, premium: 'Added post-beta' },
  //   { feature: 'Value Add - Additional Learning Content/Website UEx refresh/AI review of current questions for QA/More questions and dripped/automation', free: false, premium: 'Added post-beta' }
];

export const TableComponent: React.FC = () => (
  <Table>
    <thead>
      <Tr>
        <Th>Feature</Th>
        <Th>Free</Th>
        <Th>Premium</Th>
      </Tr>
    </thead>
    <tbody>
      {features.map((item, index) => (
        <Tr key={index}>
          <Td>
            {item.feature}{" "}
            {item.link && (
              <span
                style={{
                  fontSize: "12px",
                  color: "blue",
                  cursor: "pointer"
                }}
              >
                {" "}
                (terms and conditions){" "}
              </span>
            )}
          </Td>
          <Td>
            {item.free === true ? (
              <CheckMark>✔</CheckMark>
            ) : item.free === false ? (
              <CrossMark>✘</CrossMark>
            ) : (
              item.free
            )}
          </Td>
          <Td>
            {item.premium === true ? (
              <CheckMark>✔</CheckMark>
            ) : item.premium === false ? (
              <CrossMark>✘</CrossMark>
            ) : (
              item.premium
            )}
          </Td>
        </Tr>
      ))}
    </tbody>
  </Table>
);

export default TableComponent;
