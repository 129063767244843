import React from "react";

import styled, { keyframes } from "styled-components";

import { SizeMe } from "react-sizeme";

interface IProps {
  setSize?: number;
}

const Loading: React.FunctionComponent<IProps> = ({ setSize }) => {
  if (!setSize)
    return (
      <SizeMe monitorWidth={true} monitorHeight={true}>
        {({ size }) => {
          if (
            size &&
            size.width &&
            size.width >= 40 &&
            size.height &&
            size.height >= 40
          ) {
            const spinerSize = Math.round(
              Math.min(size.width, size.height) / 2
            );
            return (
              <Container>
                <Spinner widthAndHeight={spinerSize} />
              </Container>
            );
          }
          return <div />;
        }}
      </SizeMe>
    );
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        marginTop: 20,
        marginBottom: 20
      }}
    >
      <Container>
        <Spinner widthAndHeight={setSize} />
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ISize {
  widthAndHeight: number;
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  `;

const Spinner = styled.div<ISize>`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: ${p => p.widthAndHeight}px;
  height: ${p => p.widthAndHeight}px;
  position: relative;
  animation: ${spin} 2s linear infinite;
` as React.FunctionComponent<ISize>;

export default Loading;
