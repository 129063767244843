import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
//router
import { Link } from "react-router-dom";
import { frontEnd } from "../../config/links";

//components
import { ButtonPrimary } from "../../components/buttons/button";

//assets
import HomePageTopImage from "../../assets/HomePageTopImage.png";
import HomePageTopImageWebp from "../../assets/HomePageTopImage.webp";
import HomePageTopImageWebpSmall from "../../assets/HomePageTopImage.small.webp";
import { MQ, SV } from "../../styles/styles-variables";

const LandingSection = () => {
  return (
    <section id="landing-section">
      <FlexBar>
        <div style={{ flexShrink: 10 }}>
          <H1>Passing an exam is easier than you thought.</H1>
          <div
            style={{
              fontSize: "18px",
              fontWeight: 500,
              margin: "30px 0 50px 0"
            }}
          >
            <div>Don't wait. </div>
            <div>
              Sign up now for full access to over 1700 new questions and 1500
              questions from "The Bank".
            </div>
          </div>
          <StyledLink
            to={frontEnd.register}
            style={{ margin: "0 auto", width: "100%" }}
          >
            <ButtonPrimary text="Try for free" />
            <br />
          </StyledLink>
        </div>
        {/* <LandingImage
          src={HomePageTopImage}
          alt="Cartoon image of person reading a book"
        /> */}
        <Helmet>
          <link rel="preload" as="image" href={HomePageTopImageWebpSmall} />
        </Helmet>
        <LandingImage2>
          <source
            type="image/webp"
            media="(min-width: 850px)"
            srcSet={HomePageTopImageWebp}
          />
          <source type="image/webp" srcSet={HomePageTopImageWebpSmall} />
          <source type="image/png" srcSet={HomePageTopImage} />
          <img
            src={HomePageTopImage}
            alt="Cartoon image of person reading a book"
          />
        </LandingImage2>
      </FlexBar>
    </section>
  );
};

const H1 = styled.h1`
  line-height: 1.2;
  font-size: 36px;
  @media ${MQ.tablet} {
    font-size: 48px;
  }
`;

const StyledLink = styled(Link)`
  button {
    margin: 0 auto;
    @media ${MQ.tabletL} {
      margin: 0;
    }
  }
`;

const LandingImage2 = styled.picture`
  position: relative;
  z-index: -2;
  width: 100%;
  // flex-basis: 2;
  @media ${MQ.tabletL} {
    margin-left: -50px;
    width: 70%;
  }
`;

// const LandingImage = styled.img`
//   position: relative;
//   z-index: -2;
//   width: 100%;
//   // flex-basis: 2;
//   @media ${MQ.tabletL} {
//     margin-left: -50px;
//     width: 70%;
//   }
// `;

const FlexBar = styled.div`
  display: flex;
  margin-top: ${SV.size.sectionMargin};
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  @media ${MQ.tabletL} {
    flex-wrap: nowrap !important;
    text-align: left;
  }
`;

export default LandingSection;
