import React, { Suspense, lazy } from "react";

import styled, { css } from "styled-components";

//router
import { Route, Switch, useLocation } from "react-router-dom";
import { frontEnd } from "../../config/links";

//components
// import Login from "../../pages/authorization/login";
//import Register from "../../pages/authorization/register";
//import RegisterSuccess from "../../pages/authorization/register-success";

//assets
import { CrossIcon } from "../../components/styled-components/icons";
import { TextLink } from "../../components/styled-components/link-text";
import { MQ } from "../../styles/styles-variables";
// import ClosedForAlpha from "../authorization/closed-for-alpha";
//import ConfirmEmail from "../authorization/confirm-email";
// import ForgotPassword from "../authorization/forgot-password";
// import ResendConfirmEmail from "../authorization/resend-confirm-email";
//import ResetPassword from "../authorization/reset-password";

const ResendConfirmEmail = lazy(() =>
  import("../authorization/resend-confirm-email")
);
const ForgotPassword = lazy(() => import("../authorization/forgot-password"));
const ClosedForAlpha = lazy(() => import("../authorization/closed-for-alpha"));
const ResetPassword = lazy(() => import("../authorization/reset-password"));
const ConfirmEmail = lazy(() => import("../authorization/confirm-email"));
const Login = lazy(() => import("../../pages/authorization/login"));
const Register = lazy(() => import("../../pages/authorization/register"));
const RegisterSuccess = lazy(() =>
  import("../../pages/authorization/register-success")
);

//todo get auto container pages code splitting.
const AuthContainer = () => {
  const { pathname } = useLocation();

  const padding =
    pathname !== frontEnd.registerSuccess ? "20px 84px" : "20px 50px";
  const node = React.useRef<any>();
  // useOutsideClick(true, node, () => history.push("/"));

  return (
    <Container active={pathname !== "/"} padding={padding} ref={node}>
      <Suspense fallback={<div></div>}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <TextLink to="">
            {/* <img src={CloseCross} alt="" />  */}
            <CrossIcon style={{ width: 12, height: 12, marginRight: 5 }} />
            Close
          </TextLink>
        </div>
        <Switch>
          <Route path={frontEnd.login} exact component={Login} />
          <Route path={frontEnd.register} exact component={Register} />
          <Route
            path={frontEnd.registerSuccess}
            exact
            component={RegisterSuccess}
          />
          <Route
            path={frontEnd.forgotPassword}
            exact
            component={ForgotPassword}
          />
          <Route path={frontEnd.confirmEmail} exact component={ConfirmEmail} />
          <Route
            path={frontEnd.resendConfirmEmail}
            exact
            component={ResendConfirmEmail}
          />
          <Route
            path={frontEnd.resetPassword}
            exact
            component={ResetPassword}
          />
          <Route
            path={frontEnd.closedForAlpha}
            exact
            component={ClosedForAlpha}
          />
          {/* <Route component={Register} /> */}
        </Switch>
      </Suspense>
    </Container>
  );
};
//
const Container = styled.div<{ active: boolean; padding: string }>`
  position: absolute;
  top: -682px;
  right: -60px;
  /* left: -330px; */
  /*   
  for fixed
  right: 0;
  top: -670px;
 
  */
  /* margin-top: -670px;
  top: 640px; */
  /* todo make  - half of navbar height */
  height: 670px;
  width: 460px;
  background-color: white;
  /* position: relative; */
  z-index: 900;
  opacity: 0.98;
  padding: ${p => p.padding};
  border-radius: 0 0 5px 5px;
  transition: all 0.4s ease-in-out;
  overflow-y: hidden;
  ${p =>
    p.active &&
    css`
      transform: translateY(100%);
      /* outline: 9999px solid rgba(0, 0, 0, 0.5); */
    `}
 @media ${MQ.mobileL}{
  left: -360px;
 }
  /* width of container */
 @media(min-width: 1240px) {
    right: calc((100vw - 1240px) / 2);
  }
`;

export default AuthContainer;
