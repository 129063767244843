import React from "react";

import styled from "styled-components";

import { H2 } from "../authorization/styled-components";
//assets

import MiddleBackground from "../../assets/middleBackground.svg";
import ExperinceIcon1 from "../../assets/experience-icon1.png";
import ExperinceIcon2 from "../../assets/experience-icon2.png";
import ExperinceIcon3 from "../../assets/experience-icon3.png";
import ExperinceIcon4 from "../../assets/experience-icon4.png";
import ExperienceImage1 from "../../assets/DashboardExample.png";
import ExperienceImage1wbp from "../../assets/DashboardExample.webp";
import ExperienceImage1wbpsmall from "../../assets/DashboardExample.small.webp";
import ExperienceImage2 from "../../assets/ReviseExample.png";
import ExperienceImage2wbp from "../../assets/ReviseExample.webp";
import ExperienceImage2wbpsmall from "../../assets/ReviseExample.small.webp";
import ExperienceImage3 from "../../assets/QuestionExample.png";
import ExperienceImage3wbp from "../../assets/QuestionExample.webp";
import ExperienceImage3wbpsmall from "../../assets/QuestionExample.small.webp";
import ExperienceImage4 from "../../assets/ProgressExample.png";
import ExperienceImage4wbp from "../../assets/ProgressExample.webp";
import ExperienceImage4wbpsmall from "../../assets/ProgressExample.small.webp";

import { SV, MQ } from "../../styles/styles-variables";
import { SpacerBar } from "../../components/bar";

const ExperienceSection = () => {
  return (
    <section
      style={{
        // position: "relative",
        marginTop: "120px"
      }}
      id="experience"
    >
      <MiddleBG src={MiddleBackground} alt="" />
      <Container>
        {/* todo create animation */}
        <H2 style={{ marginBottom: "60px" }}>
          Discover the GetThru™ experience
        </H2>
        <ExperienceBlock>
          <ExperienceText>
            <img src={ExperinceIcon1} alt="" />
            <H2> Monitor your progress</H2>
            <p>
              Track your progress over time and identify areas of weakness to
              streamline your study.
            </p>
          </ExperienceText>
          <picture>
            <source
              type="image/webp"
              media="(min-width: 650px)"
              srcSet={ExperienceImage1wbp}
            />
            <source srcSet={ExperienceImage1wbpsmall} />
            <source type="image/png" srcSet={ExperienceImage1} />
            <img src={ExperienceImage1} alt="" />
          </picture>
          {/* <img src={ExperienceImage1} alt="" /> */}
        </ExperienceBlock>
        <SpacerBar />
        <ExperienceBlock>
          <ExperienceText>
            <img src={ExperinceIcon2} alt="" />
            <H2> Tailor your study session</H2>
            <p>
              Select sub-topics to focus your study on to maximise knowledge
              reinforcement, or simulate the exam by tackling a question from
              any area.
            </p>
          </ExperienceText>
          <picture>
            <source
              type="image/webp"
              media="(min-width: 650px)"
              srcSet={ExperienceImage2wbp}
            />
            <source srcSet={ExperienceImage2wbpsmall} />
            <source type="image/png" srcSet={ExperienceImage2} />
            <img src={ExperienceImage2} alt="" />
          </picture>
          {/* <img src={ExperienceImage2} alt="" /> */}
        </ExperienceBlock>
        <SpacerBar />
        <ExperienceBlock>
          <ExperienceText>
            <img src={ExperinceIcon3} alt="" />
            <H2> Original Content Tailored to the GSSE</H2>
            <p>
              GetThru™ has over 1700 questions in the same style as the GSSE
              with explanations and references to Last's Anatomy, Ganong's
              Physiology and Robbin's Pathology. Practice makes perfect!
            </p>
          </ExperienceText>
          <picture>
            <source
              type="image/webp"
              media="(min-width: 650px)"
              srcSet={ExperienceImage3wbp}
            />
            <source srcSet={ExperienceImage3wbpsmall} />
            <source type="image/png" srcSet={ExperienceImage3} />
            <img src={ExperienceImage3} alt="" />
          </picture>
          {/* <img src={ExperienceImage3} alt="" /> */}
        </ExperienceBlock>
        <SpacerBar />
        <ExperienceBlock>
          <ExperienceText>
            <img src={ExperinceIcon4} alt="" />
            <H2> User Experience</H2>
            <p>
              A clean and artfully designed online environment has been created
              for you to supercharge your study – and the mobile platform allows
              you to study from anywhere for as long as you like!
            </p>
          </ExperienceText>
          <picture>
            <source
              type="image/webp"
              media="(min-width: 650px)"
              srcSet={ExperienceImage4wbp}
            />
            <source srcSet={ExperienceImage4wbpsmall} />
            <source type="image/png" srcSet={ExperienceImage4} />
            <img src={ExperienceImage4} alt="" />
          </picture>
          {/* <img src={ExperienceImage4} alt="" /> */}
        </ExperienceBlock>
        <SpacerBar />
      </Container>
    </section>
  );
};

const ExperienceBlock = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  flex-wrap: wrap;
  > img {
    width: 350px;
    border: 2px solid ${SV.colors.darkMain1};
    border-radius: 26px;
    z-index: -1;
    box-shadow: 0px 13px 15px rgba(11, 27, 83, 0.08);
    margin-left: -10px;
    margin-right: -10px;
    @media ${MQ.tablet} {
      width: 681px;
      border-width: 3px;
    }
  }
  > picture > img {
    width: 350px;
    border: 2px solid ${SV.colors.darkMain1};
    border-radius: 26px;
    z-index: -1;
    box-shadow: 0px 13px 15px rgba(11, 27, 83, 0.08);
    margin-left: -10px;
    margin-right: -10px;
    @media ${MQ.tablet} {
      margin-right: -20px;
      width: 685px;
      border-width: 3px;
    }
  }
  > div {
    width: 360px;
  }
`;

const ExperienceText = styled.div`
  text-align: center;
  ${H2} {
    text-align: center;
    font-size: 18px;
    @media ${MQ.mobileL} {
      font-size: 32px;
      text-align: left;
    }
  }
  img {
    margin: 60px 30px 30px 30px;
    display: none;
    @media ${MQ.tablet} {
      display: block;
    }
  }
  * {
    text-align: left;
    color: ${SV.colors.darkMain1};
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 20px;
    @media ${MQ.mobileL} {
      text-align: left;
    }
  }
`;

const MiddleBG = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  // width: 90vw;
  // aspect-ratio: 1399 / 1210
  height: 90vh;
  transform: translateY(-25%);
  z-index: -1;
  /* transform: translateX-100%); */
`;

const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  z-index: -1;
  padding: 0 40px;
  @media (min-width: 1240px) {
    padding: 0;
  }
`;

export default ExperienceSection;
