import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { SV } from "../../styles/styles-variables";

export const SignUpDiv = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
`;

export const SmallTextLink = styled(Link)`
  color: ${SV.colors.interactive1};
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  :hover {
    text-decoration-line: underline;
  }
  :active {
    text-decoration-line: underline;
  }
  :focus {
    text-decoration-line: underline;
  }
`;

export const H2 = styled.h2`
  text-align: center;
  font-size: 32px;
  color: ${SV.colors.darkMain1};
  margin-bottom: 34px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 50px;
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin: 5px 0;
  }
  input {
    appearance: none;
    outline: none;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    height: 36px;
    padding: 10px;
    color: grey;
    transition: all 0.3s ease-in-out;
    &:focus {
      color: black;
      border: 1px solid ${SV.colors.interactive1};
    }
  }
  button {
    margin: 8px 0;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 2px;
`;
