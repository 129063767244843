import React from "react";
import { Link } from "react-router-dom";

const Http404Page = () => (
  <>
    <div> Http 404 Error: Page not found </div>
    <Link to="/">return home </Link>{" "}
  </>
);

export default Http404Page;
