import React from "react";
import styled from "styled-components";

import LogoSmall from "../../assets/logo-small.svg";

const Logo = () => {
  return (
    <FlexRow>
      <img src={LogoSmall} alt="" />{" "}
      <LogoText>
        {" "}
        Get Thru <em>(beta)</em>{" "}
      </LogoText>
      {/* <span style={{ color: "red", fontSize: 16 }}> Surgical</span> */}
    </FlexRow>
  );
};

const LogoText = styled.h3`
  font-weight: bold;
  font-size: 22px;
  padding: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const LogoImage = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;

  /* Blue 1 */
  background: #2f80ed;
  border-radius: 5px;
  transform: rotate(-45deg);
`;

export default Logo;
