import React from "react";

import styled, { css } from "styled-components";

import Spinner from "../../assets/white-on-blue-spinner2.gif";

// import { boolean } from "yup";
import { SV } from "../../styles/styles-variables";

interface IProps {
  color?: string;
  text?: string;
  onClick?: any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  loading?: boolean;
  wide?: boolean;
  style?: any;
}

const Button: React.FunctionComponent<IProps> = ({ color }) => {
  return <button style={{ color }}>Click Me</button>;
};

const ButtonPrimary: React.FunctionComponent<IProps> = ({
  text,
  onClick,
  disabled,
  type,
  children,
  loading,
  wide = false,
  style = {}
}) => {
  return (
    <StyledButtonPrimary
      onClick={onClick}
      disabled={disabled}
      type={type}
      wide={wide}
      style={{ ...(style as any) }}
    >
      <span style={{ lineHeight: 0 }}> {text} </span>
      {loading && (
        <img
          src={Spinner}
          alt="loading"
          style={{ width: 15, height: 15, marginLeft: "10px" }}
        />
      )}
    </StyledButtonPrimary>
  );
};

const ButtonSecondary: React.FunctionComponent<IProps> = ({
  text,
  onClick,
  disabled,
  type,
  children,
  style
}) => {
  return (
    <StyledButtonSecondary
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={style}
    >
      <span> {text}</span>
      {children}
    </StyledButtonSecondary>
  );
};

const ButtonTertiary: React.FunctionComponent<IProps> = ({
  children,
  onClick,
  disabled,
  style = {}
}) => {
  return (
    <StyledButtonTertiary
      onClick={onClick}
      style={{ ...style }}
      disabled={disabled}
    >
      {children}
    </StyledButtonTertiary>
  );
};

const StyledButtonTertiary = styled.button`
  border: 1px solid ${SV.colors.interactive1};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-weight: 500;
  font-size: 14px;
  :disabled span {
    color: ${SV.colors.disabled2};
  }
  height: 34px;
  padding: 0 14px;
  transition: all 0.2s ease-in-out;
  ${p =>
    p.disabled &&
    css`
      background-color: ${SV.colors.disabled1};
      border: 1px solid ${SV.colors.disabled2};
    `}
  :enabled:hover {
    background-color: ${SV.colors.interactive2};
  }
  :focus {
    box-shadow:  0px 0px 5px  ${SV.colors.interactiveDark};
    /* outline: 2px solid ${SV.colors.interactiveDark}; */
    *{
      text-decoration-line: none;
    }
  }
  text-decoration-line: none !important;
`;

const StyledButtonSecondary = styled.button`
  /* box-sizing: content-box; */
  border: 1px solid ${SV.colors.interactive1};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-weight: 500;
  font-size: 14px;
  color: ${SV.colors.interactive1};
  height: 34px;
  padding: 10px 14px;
  transition: all 0.2s ease-in-out;
  ${p =>
    p.disabled &&
    css`
      background-color: ${SV.colors.disabled1} !important;
    `}
  :hover {
    background-color: ${SV.colors.interactive2} !important;
  }
  :focus {
    box-shadow:  0px 0px 5px  ${SV.colors.interactiveDark};
    /* outline: 2px solid ${SV.colors.interactiveDark}; */
  }
`;
//

const StyledButtonPrimary = styled.button<{ wide: boolean; style: any }>`
  background-color: ${SV.colors.interactive1};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 0;
  height: 34px;
  padding: 10px 14px;
  margin: 5px 0;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  ${p => p.wide && "width: 100%;"}
  ${p =>
    p.disabled &&
    css`
      background-color: ${SV.colors.disabled1};
    `}
    :hover {
    background-color: ${SV.colors.interactiveDark};
    /* box-shadow:  0px 0px 5px  ${SV.colors.interactiveDark}; */
  }
  :disabled{
    background-color: ${SV.colors.disabled1} !important;
    box-shadow: 0 0 0 black;
    cursor: not-allowed;
  }
  :focus {
    box-shadow:  0px 0px 5px  ${SV.colors.interactiveDark};
    /* outline: 2px solid ${SV.colors.interactiveDark}; */
  }
  :active{
    box-shadow: inset 0px 0px 5px  black;
  }
`;

export { Button, ButtonPrimary, ButtonSecondary, ButtonTertiary };

export default Button;
