const frontEnd = {
  profile: "/profile",

  bulkUpload: "/bulk-upload",
  reviewReports: "/review-reports",
  singleUpload: "/single-upload",
  results: "/results",
  review: "/review",
  test: "/test",
  admin: "/admin",
  //
  // authorization links
  //
  login: "/login/index.html",
  register: "/register/index.html",
  registerSuccess: "/register-success",
  confirmEmail: "/confirm-email/index.html",
  resendConfirmEmail: "/resend-confirm-email",
  closedForAlpha: "/closed-for-alpha",
  resetPassword: "/reset-password/index.html",
  forgotPassword: "/forgot-password",
  //
  // dashboard links
  //
  revise: "/dashboard/revise",
  dashboardHome: "/dashboard/home",
  testResults: "/dashboard/test-results",
  purchase: "/dashboard/purchase",
};

const backEnd = {
  changePassword: "/api/auth/change-password"
};

const links = {
  frontEnd,
  backEnd
};

export default links;
export { frontEnd, backEnd };
