import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 10px; /* wouldn't allow users to overwrite base font size*/
    /* font-size: 62.5%; allows users to overwrite font size but translates to 10px */
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "europa", "Roboto", sans-serif;
    color: #010842;
    overflow-x: visible;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.65;
    box-sizing: border-box;
    letter-spacing: -1;
  }

  button:focus {
    outline: 0;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  a {
    text-decoration: none;
  }
  
  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }
  
  
  button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: inherit; 
  }

  ul{
    list-style: none;
    list-style-type: none !important;
  }





*:focus {
    outline: none;
}

/* todo make only appear when active/on hover */
::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #1769f0;
  color: #1769f0;
  border: 2px none #ffffff;
  border-radius: 50px;
  opacity:0;
  box-shadow: inset 0 0 0 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #1769f0;
}
::-webkit-scrollbar-thumb:active {
  background: #1769f0;
}
::-webkit-scrollbar-track {
  background: #999999;
  border: 0px none inherit;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #999999;
}
::-webkit-scrollbar-track:active {
  background: #999999;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
`;

export default GlobalStyles;
